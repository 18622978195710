function makeString(arr) {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(', ') + ' and ' + last;
}

function replaceTagName(htmlString, tagName, newTagName) {
  return htmlString?.replace(`<${tagName}`, `<${newTagName}`).replace(`</${tagName}>`, `</${newTagName}>`);
}

function getHtmlText(html) {
  if (!html?.length) return '';

  const divEl = document.createElement('div');
  divEl.innerHTML = html;
  return divEl.innerText;
}

// Decode a Punycode-encoded string into an array of Unicode code points.
function punycodeUcs2Decode(input) {
  const output = [];
  let i = 0;
  const length = input.length;
  
  while (i < length) {
    const value = input.charCodeAt(i++);
    if (value >= 0xD800 && value <= 0xDBFF && i < length) {
      // It's a high surrogate, and there is a next character.
      const extra = input.charCodeAt(i++);
      if ((extra & 0xFC00) === 0xDC00) {
        // Low surrogate.
        output.push(((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000);
      } else {
        // It's an unmatched surrogate; only append this code unit, in case the next
        // code unit is the high surrogate of a surrogate pair.
        output.push(value);
        i--;
      }
    } else {
      output.push(value);
    }
  }

  return output;
}

export const addMailto = (string = '') => {
  const containAtSymbolRegex = /^\S+@\S+\.\S+$/;

  return containAtSymbolRegex.test(string) && string.indexOf('mailto:') ? `mailto:${string}` : string;
}

export {
  makeString,
  replaceTagName,
  getHtmlText,
  punycodeUcs2Decode,
}

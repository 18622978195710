// Function to get an item from localStorage by ID
export function getLocalItemById(id) {
  try {
    const item = localStorage.getItem(id);

    return item ? JSON.parse(item) : null;
  } catch (_) {
    return null;
  }
  
}

// Function to set an item in localStorage by ID
export function setLocalItemById(id, value) {
  localStorage.setItem(id, JSON.stringify(value));
}

import { withAuthHeader } from './auth-jwt';

const DEFAULT_HEADERS = {
  'Accept': 'application/json',
  'user-agent': 'Mozilla/4.0 MDN',
  'content-type': 'application/json',
};

// Default options are marked with *
const DEFAULT_OPTIONS = {
  cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *omit
  mode: 'cors', // no-cors, *same-origin
  redirect: 'follow', // *manual, follow, error
  referrer: 'no-referrer', // *client
};

const _isStage = () => process.env.REACT_APP_X_STAGE.toLowerCase() === 'true';

function deleteData(url, data, withApiKey = false) {
  const method = 'DELETE';
  const body = JSON.stringify(data);
  let headers = withAuthHeader(DEFAULT_HEADERS);

  if (withApiKey === true) {
    headers = {
      ...headers,
      apikey: process.env.REACT_APP_API_KEY_HEADER
    };
  }

  const opts = {body, method, headers, ...DEFAULT_OPTIONS};

  return fetchApi(url, opts);
}

function putData(url, data, withApiKey = false) {
  const method = 'PUT';
  const body = JSON.stringify(data);
  let headers = withAuthHeader(DEFAULT_HEADERS);

  if (withApiKey === true) {
    headers = {
      ...headers,
      apikey: process.env.REACT_APP_API_KEY_HEADER
    };
  }

  const opts = {body, method, headers, ...DEFAULT_OPTIONS};

  return fetchApi(url, opts);
}

function postData(url, data, withApiKey = false, token = null, removeAuthKey = false) {
  const method = 'POST';
  const body = JSON.stringify(data);
  let headers = removeAuthKey ? DEFAULT_HEADERS : withAuthHeader(DEFAULT_HEADERS);

  if (token) {
    headers = {
      ...headers,
      Authorization:`Bearer ${token}`
    };
  }

  if (withApiKey === true) {
    headers = {
      ...headers,
      apikey: process.env.REACT_APP_API_KEY_HEADER
    };
  }

  const opts = {body, method, headers, ...DEFAULT_OPTIONS};

  return fetchApi(url, opts);
}

function getData(url, withApiKey = false, signal: AbortSignal = null, token = null) {
  const method = 'GET';
  let headers = withAuthHeader({ 'Accept': 'application/json' });

  if (withApiKey === true) {
    headers = {
      ...headers,
      apikey: process.env.REACT_APP_API_KEY_HEADER
    };
  }

  if (token !== null) {
    headers = {
      ...headers,
      Authorization:`Bearer ${token}`
    };
  }

  if (_isStage() === true) {
    headers = {
      ...headers,
      'x-environment': 'stage'
    }
  }

  const opts = { method, headers, signal, ...DEFAULT_OPTIONS };

  return fetchApi(url, opts);
}

function getDataNoAuth(url) {
  const method = 'GET';
  let headers = { 'Accept': 'application/json' };

  if (_isStage() === true) {
    headers = {
      ...headers,
      'x-environment': 'stage'
    }
  }

  const opts = { method, headers, ...DEFAULT_OPTIONS };

  return fetchApi(url, opts);
}

function queryGraphQL(url, query, variables) {
  const method = 'POST';
  const body = JSON.stringify({
    query,
    ...variables && { variables }
  });

  let headers = {
    ...DEFAULT_HEADERS,
  };

  if (_isStage() === true) {
    headers = {
      ...headers,
      'x-environment': 'stage'
    }
  }

  const opts = {body, method, headers, ...DEFAULT_OPTIONS};

  return fetchApi(url, opts);
}

function fetchApi(url, opts) {
  return fetch(url, opts).then(response => {
    // Shorthand to check for an HTTP 2xx response status.
    if (response.ok) {
      return response;
    }
    // Raise an exception to reject the promise and trigger the outer .catch() handler.
    // By default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
    throw response;
  }).then(function(response) {
    return response.json();
  }).catch(
    error => {
      if(error.json) { // a response throwed above
        return error.json().then(json => {
          const err = Object.assign({}, json, {
            status: error.status,
            statusText: error.statusText,
          });
          return Promise.reject(err);
        });
      }
      console.error(error);
      return Promise.reject(error);
    }
  );
}

export {getData, putData, postData, deleteData, queryGraphQL, fetchApi, getDataNoAuth};

import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import { getDataNoAuth, putData } from '../utils/fetch-api';

const baseSlsUrl = process.env.REACT_APP_BASE_API_URL;

// API Helpers
const buildCirculationUrl = (storyId, dryrun = false) => {
  const baseUrl = `${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${storyId}/circulation/philly-media-network`;
  const params = new URLSearchParams();

  if (dryrun) {
    params.append('dryrun', 'true');
    params.append('collision_behavior', 'increment');
  }

  return params?.toString() ? `${baseUrl}?${params?.toString()}` : baseUrl;
};

const getStoryCirculations = async (storyId) => {
  if (!storyId) return [];

  try {
    return await getDataNoAuth(
      `${baseSlsUrl}arc-content/arc-proxy/draft/v1/story/${storyId}/circulation`
    );
  } catch (error) {
    console.error(error);

    return [];
  }
};

const handleStoryCirculations = (storyId, selectedCirculations, dryrun) => {
  if (!storyId || !selectedCirculations?.length) return;

  const updateTasks = selectedCirculations.map((circulation) =>
    putData(buildCirculationUrl(storyId, dryrun), dryrun ? _omit(circulation, 'website_url') : circulation, true)
  );

  return Promise.all(updateTasks);
};

// Data Conversion Helpers
const mssToCirculationDataConverter = (mssSelection) => {
  return Object.entries(mssSelection.websites).map(
    ([websiteId, websiteData]) => {
      const circulation = {
        website_id: websiteId,
        website_sections: mssSelection.taxonomy.sections,
      };

      if (websiteData.website_url)
        circulation.website_url = websiteData.website_url;

      if (
        websiteData.website_section &&
        Object.keys(websiteData.website_section).length
      ) {
        circulation.website_primary_section = websiteData.website_section;

        const isPrimarySectionInAllSections = circulation.website_sections.some(
          (section) =>
            section.referent.id === websiteData.website_section.referent.id
        );

        if (!isPrimarySectionInAllSections) {
          circulation.website_sections.push(websiteData.website_section);
        }
      } else {
        const rootSection = {
          referent: {
            id: '/',
            type: 'section',
            website: websiteId,
          },
          type: 'reference',
        };

        circulation.website_primary_section = rootSection;
        circulation.website_sections.push(rootSection);
      }

      return circulation;
    }
  );
};

// Section Helpers
const getSelectedSectionsAns = (selectedSections = []) => {
  return selectedSections
    .filter((section) => section._id !== '/')
    .map((section) => ({
      type: 'reference',
      referent: {
        id: section._id,
        type: 'section',
        website: section._website || 'philly-media-network',
        provider: '',
      },
    }));
};

const getPrimarySectionsAns = (siteProps = {}) => {
  const sites = ['philly-media-network'];

  return sites.reduce((selectedPrimarySectionsAns, site) => {
    if (siteProps[site]?.primarySectionId) {
      const websiteData = {
        website_section: {
          type: 'reference',
          referent: {
            id: siteProps[site].primarySectionId,
            type: 'section',
            website: site,
            provider: '',
          },
        },
      };

      if (siteProps[site].website_url) {
        websiteData.website_url = siteProps[site].website_url;
      }

      return { ...selectedPrimarySectionsAns, [site]: websiteData };
    }

    return selectedPrimarySectionsAns;
  }, {});
};

// Main Function
// options: { dryrun: boolean, forceUpdate: boolean }
// dryrun = true is used for getting preview new url which will be generated
// forceUpdate = true is used for updating circulation data even if there is no change
export const handleStoryCirculation = async (article, options) => {
  const storyId = article?._id;
  const siteProps = {
    'philly-media-network': {
      primarySectionId: article?.taxonomy?.primary_section?._id,
      website_url: article?.canonical_url,
    },
  };
  const selectedSections = article?.taxonomy?.sections;
  const sections = getSelectedSectionsAns(selectedSections);
  const websites = getPrimarySectionsAns(siteProps);

  const selectedCirculationData = {
    taxonomy: { sections },
    websites,
  };

  const { circulations } = await getStoryCirculations(storyId);
  const selectedCirculations = mssToCirculationDataConverter(
    selectedCirculationData
  );

  const filteredSelectedCirculations = selectedCirculations.filter(
    (circulation) =>
      Object.keys(selectedCirculationData.websites).includes(
        circulation.website_id
      )
  );

  filteredSelectedCirculations.forEach((circulation) => {
    delete circulation.website_primary_section?.referent.provider;

    circulation.website_sections?.forEach((section) => {
      delete section.referent.provider;
    });
  });

  const isUpdateRequired = filteredSelectedCirculations.some((circulation) => {
    const oldCirculation = circulations.find(
      (c) => c.website_id === circulation.website_id
    );

    if (!oldCirculation) return true;

    const oldData = {
      website_primary_section: oldCirculation.website_primary_section,
      website_sections: oldCirculation.website_sections,
    };

    const newData = {
      website_primary_section: circulation.website_primary_section,
      website_sections: circulation.website_sections,
    };

    return !_isEqual(oldData, newData);
  });
  
  if (isUpdateRequired || options?.dryrun || options?.forceUpdate) {
    return await handleStoryCirculations(
      storyId,
      filteredSelectedCirculations,
      options?.dryrun === true,
    );
  } else {
    console.log('No changes in circulations');

    return [];
  }
};
